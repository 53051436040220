import axios from "axios";
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import { getCollectionsArrays, getOneCollectionArray } from "./LSManager";
import Offer from "./Offer";
import CircularProgress from '@mui/material/CircularProgress';

export default function OffersList({ mode }) {

    let { type } = useParams();
    let { priceFrom } = useParams();
    let { priceTo } = useParams();
    let { page } = useParams();
    let { keyword } = useParams();


    const [listData, setListData] = React.useState();
    const [isLoading, setIsLoading] = React.useState(false);
    const [locations, setLocations] = React.useState([]);

    React.useEffect(() => {
        if (mode == "normal") {
            setIsLoading(true);

            axios.get('https://midomek.niewolski.pl/lokacje.php')
                .then((lokacjeResponse) => {
                    setLocations(lokacjeResponse.data);
                    axios.get(`https://midomek.niewolski.pl/dataEndpoint.php?f=offers&type=${type}&priceFrom=${priceFrom}&priceTo=${priceTo}&page=${page}&offersPerPage=20`)
                        .then((response) => {
                            setIsLoading(false);
                            setListData(response.data);
                        }, (error) => {
                            setIsLoading(false);
                            console.log(error);
                        });
                })


        } else if (mode == "search") {
            setIsLoading(true);
            axios.get('https://midomek.niewolski.pl/lokacje.php')
                .then((lokacjeResponse) => {
                    setLocations(lokacjeResponse.data);
                    axios.get(`https://midomek.niewolski.pl/dataEndpoint.php?f=search&type=${type}&text=${keyword}`)
                        .then((response) => {
                            setIsLoading(false);
                            setListData(response.data);
                        }, (error) => {
                            setIsLoading(false);
                            console.log(error);
                        });
                })


        } else if (mode == "search2") {
            setIsLoading(true);
            axios.get('https://midomek.niewolski.pl/lokacje.php')
                .then((lokacjeResponse) => {
                    setLocations(lokacjeResponse.data);
                    axios.get(`https://midomek.niewolski.pl/dataEndpoint.php?f=search2&type=${type}&text=${keyword}&priceFrom=${priceFrom}&priceTo=${priceTo}&page=${page}&offersPerPage=20`)
                        .then((response) => {
                            setIsLoading(false);
                            setListData(response.data);
                        }, (error) => {
                            setIsLoading(false);
                            console.log(error);
                        });
                })


        } else if (mode == "favorites") {
            setIsLoading(false);
            setListData(Object.values(getOneCollectionArray("favorites")));
        }
        document.body.scrollTop = document.documentElement.scrollTop = 0;


    }, [mode, type, priceFrom, priceTo, page, keyword])


    const pagination = () => {
        if (mode == "normal") {
            //console.log(mode);
            return (
                <div>

                    {Array.from(Array(15 + parseInt(page)).keys()).map(n => <Link style={{ display: "inline-block", margin: "2px", padding: "3px 6px", background: page == n ? "#1976D2" : "#eeeeee", color: page == n ? "white" : "black", borderRadius: "5px", textDecoration: "none" }} to={`/offers/${type}/${priceFrom}/${priceTo}/${n}`}>{n + 1}</Link>)}
                    <Link style={{ display: "inline-block", margin: "2px", padding: "3px 6px", background: "#cf5a91", color: "black", borderRadius: "5px", textDecoration: "none" }} to={`/favorites`}>ulubione</Link>
                </div>
            )
        } else if (mode == "search2") {
            //console.log(mode);
            return (
                <div>
                    <div>Wyszukujesz słowo kluczowe '{keyword}' w  kwocie od {priceFrom} do {priceTo} ({type})</div>
                    {Array.from(Array(15 + parseInt(page)).keys()).map(n => <Link style={{ display: "inline-block", margin: "2px", padding: "3px 6px", background: page == n ? "#1976D2" : "#eeeeee", color: page == n ? "white" : "black", borderRadius: "5px", textDecoration: "none" }} to={`/search2/${keyword}/${type}/${priceFrom}/${priceTo}/${n}`}>{n + 1}</Link>)}
                    <Link style={{ display: "inline-block", margin: "2px", padding: "3px 6px", background: "#cf5a91", color: "black", borderRadius: "5px", textDecoration: "none" }} to={`/favorites`}>ulubione</Link>
                </div>
            )
        } else {
            return (
                <div>
                    <Link style={{ display: "inline-block", margin: "2px", padding: "3px 6px", background: "#cf5a91", color: "black", borderRadius: "5px", textDecoration: "none" }} to={`/`}>wróć do ofert</Link>
                </div>
            )
        }
    }

    const dataShow = () => {
        console.log("REPAINT");
        let collections = getCollectionsArrays();
        if (listData) return listData.map(offer => <Offer data={offer} key={offer.id} collections={collections} locations={locations} />)
    }

    const loadingScreen = () => {
        return (
            isLoading ?
                <div className="loadingScreen">
                    <CircularProgress style={{ color: "white" }} size={100} color="inherit" />
                </div> :
                <></>
        )
    }


    return (
        <>
            {loadingScreen()}
            {pagination()}
            {dataShow()}
            {pagination()}
        </>
    )
}