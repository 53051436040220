import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, TextField } from '@mui/material';

function SettingsPanel({type,setType,priceFrom,setPriceFrom,priceTo,setPriceTo,showOffers}) {



    const handleChangeType = (event) => {
        setType(event.target.value);
    }

    const handleChangePriceFrom = (event) => {
        setPriceFrom(event.target.value);
    }

    const handleChangePriceTo = (event) => {
        setPriceTo(event.target.value);
    }

    return (
        <div className="settingsPanel">
            <div style={{ margin: "2px 3px" }}>

                <Select
                    labelId="type-select-label"
                    id="type-select"
                    value={type}
                    onChange={handleChangeType}
                    label="Typ"
                    size="small"
                >
                    <MenuItem value="homes">Domy</MenuItem>
                    <MenuItem value="plots">Działki</MenuItem>
                    <MenuItem value="flats">Mieszkania</MenuItem>
                </Select>
            </div>
            <div style={{ margin: "0 3px", width: "130px" }}>
                <TextField
                    id="input-pricefrom"
                    label="Cena od"
                    size="small"
                    value={priceFrom}
                    onChange={handleChangePriceFrom}
                />
            </div>
            <div style={{ margin: "2px 3px", width: "130px" }}>
                <TextField
                    id="input-priceto"
                    label="Cena do"
                    size="small"
                    value={priceTo}
                    onChange={handleChangePriceTo}
                />
            </div>
            <Button variant="contained" color="secondary" onClick={showOffers}>
                Szukaj
            </Button>



        </div>
    )
}

export default SettingsPanel;