import React from "react";
import {
    Switch,
    Route,
    Link,
    Redirect,
    useParams
} from "react-router-dom";
import OffersList from "./OffersList";

function ResultList() {



    return (
        <>
            <Switch>
                <Route exact path="/offers/:type/:priceFrom/:priceTo/:page">
                    <OffersList mode="normal" />
                </Route>
                <Route path="/search/:keyword">
                    <OffersList mode="search" />
                </Route>
                <Route path="/search2/:keyword/:type/:priceFrom/:priceTo/:page">
                    <OffersList mode="search2" />
                </Route>
                <Route path="/favorites">
                    <OffersList mode="favorites" />
                </Route>
                <Route path="/">
                    <Redirect to="/offers/homes/100000/2000000/0" />
                </Route>


            </Switch>
        </>
    )
}

export default ResultList;