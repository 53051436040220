import React from "react";
import "./offer.css";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { addOfferToCollection, checkOfferInCollection, removeOfferFromCollection } from "./LSManager";

export default function Offer({ data, collections, locations }) {
    const [expanded, setExpanded] = React.useState(false);
    const [inFavorities, setInFavorities] = React.useState(checkOfferInCollection(collections.favorites, data));
    const [inChecked, setInChecked] = React.useState(checkOfferInCollection(collections.checked, data));

    const getLocationMarks = () => {
        let results = [];
        locations.forEach(location => {
            if (data.title.toLowerCase().includes(location.keyword) || data.short_description.toLowerCase().includes(location.keyword) || data.long_description.toLowerCase().includes(location.keyword)) {
                if (location.category.substr(0,1) == "A") location.color = "#99FF99";
                else if (location.category.substr(0,1) == "B") location.color = "#CCFF00";
                else if (location.category.substr(0,1) == "C") location.color = "#FFCC33";
                else if (location.category.substr(0,1) == "D") location.color = "#FF9999";
                else if (location.category.substr(0,1) == "E") location.color = "#996633";
        
                results.push(location);
            }
        })
        console.log(results);

        return (
            <span>{results.map(result => {
                return <span style={{"backgroundColor": result.color}} className="locationBubble">{result.label}</span>
            })}</span>
        )
    }

    const computeCurrentColor = () => {
        return inFavorities ? inFavorities : inChecked ? inChecked : "white";
    }

    const toggleExpanded = () => {
        setExpanded(!expanded);
    }

    const parseProps = (props) => {
        var obj = JSON.parse(props);
        if (!obj) return <span class="oneprop">brak info</span>;
        console.log(Object.entries(obj));
        return Object.entries(obj).map(a => <span class="oneprop"><span class="key">{a[0]}:</span> <span class="val">{a[1]}</span></span>);
    }

    const numberWithSpaces = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    const copyURLtoClipboard = (e) => {
        e.stopPropagation();
        navigator.clipboard.writeText(data.url);
    }

    const stopEvtPropagation = (e) => {
        e.stopPropagation();
    }

    const addToCollectionButtonClicked = (e, collection, color) => {
        e.stopPropagation();       
        addOfferToCollection(collection, data, color);
    }

    const removeFromCollectionButtonClicked = (e, collection) => {
        e.stopPropagation();   
        removeOfferFromCollection(collection, data);
    }


    const addToFavoritiesButtonClicked = (e) => {
        setInFavorities("#fff0f7");
        addToCollectionButtonClicked(e, "favorites", "#fff0f7");
    }

    const removeFromFavoritiesButtonClicked = (e) => {
        setInFavorities(false);
        removeFromCollectionButtonClicked(e, "favorites");
    }

    const addToCheckedButtonClicked = (e) => {
        setInChecked("#eeeeee");
        addToCollectionButtonClicked(e, "checked", "#eeeeee");
    }

    const removeFromCheckedButtonClicked = (e) => {
        setInChecked(false);
        removeFromCollectionButtonClicked(e, "checked");
    }

    const getCollectionPanel = () => {
        return (
            <>
                <div>{inChecked ? <CheckBoxIcon style={{color:"#777777"}} fontSize="large" onClick={removeFromCheckedButtonClicked} /> : <CheckBoxOutlineBlankIcon style={{color:"#777777"}} fontSize="large" onClick={addToCheckedButtonClicked} />}</div>
                <div>{inFavorities ? <FavoriteIcon style={{color:"#cc0033"}} fontSize="large" onClick={removeFromFavoritiesButtonClicked} /> : <FavoriteBorderIcon style={{color:"#cc0033"}} fontSize="large" onClick={addToFavoritiesButtonClicked} />}</div>

            </>
        )
    }

    return (
        <div className={`offerbox ${expanded ? "expanded" : "collapsed"}`} style={{ backgroundColor: computeCurrentColor() }} >
            <div onClick={toggleExpanded} className="offerpicture">

                <img src={data.image}></img>
            </div>
            <div onClick={toggleExpanded} className="offerdescription">
                <div className="offertitle">
                    <a onClick={stopEvtPropagation} href={data.url}>{data.title}</a> <ContentCopyIcon style={{ cursor: "pointer" }} onClick={copyURLtoClipboard} />
                    {getLocationMarks()}
                </div>
                <div className="offerprice">
                    {numberWithSpaces(data.price)} zł
                    {data.oldPrice > 0 ? <span className="oldPrice">(zmiana z {numberWithSpaces(data.oldPrice)} zł)</span> : ""}
                    
                </div>
                <div className="offerothers">
                    <span>strona: {data.provider}</span>
                    <span>pierwsze pojawienie: {data.first_date}</span>
                    {data.first_date !== data.last_date ? <span>ostatnie pojawienie: {data.last_date}</span> : <></>}

                    <span>duplikatów: {data.duplicate_count}</span>
                </div>
                {expanded ?
                    <>
                        <div classname="offerprops">
                            {parseProps(data.props)}
                        </div>
                        <div className="offerlong">
                            {data.long_description}
                        </div>
                    </>
                    :
                    <div className="offershort">
                        {data.short_description}
                    </div>
                }
            </div>
            <div className="collections">
                {getCollectionPanel()}
            </div>
        </div>
    )
}