import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import ResultList from './ResultList';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsPanel from './SettingsPanel';
import {useHistory} from 'react-router-dom';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

function MainPage() {

    const[menuOpened,setMenuOpened] = React.useState(false);

    const [type, setType] = React.useState('homes');
    const [priceFrom, setPriceFrom] = React.useState(100000);
    const [priceTo, setPriceTo] = React.useState(2000000);
    //const [page, setPage] = React.useState(0);
    //const [searchWord,setSearchWord] = React.useState("");

    //const handleSearchType = (event) => {
    //    setSearchWord(event.target.value);
    //}

    const history = useHistory();

    const showOffers = () => {
        console.log(`pokaz oferty ${type} ${priceFrom} ${priceTo} `);
        setMenuOpened(false);
        history.push(`/offers/${type}/${priceFrom}/${priceTo}/0`);
    }

    const searchOffers = (searchWord) => {
        console.log(`szukj ofers: ${searchWord} ${type} ${priceFrom} ${priceTo}`);
        history.push(`/search2/${searchWord}/${type}/${priceFrom}/${priceTo}/0`);
    }

    const handleSearchKeyPress = (e) => {
        if(e.keyCode == 13){
            searchOffers(e.target.value);
         }
    }

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{ mr: 2 }}
                            onClick={() => setMenuOpened(!menuOpened)}
                        >
                            <SettingsIcon />
                        </IconButton>
                        {menuOpened ?
                        <SettingsPanel type={type} setType={setType} priceFrom={priceFrom} setPriceFrom={setPriceFrom} priceTo={priceTo} setPriceTo={setPriceTo} showOffers={showOffers} />
                        :
                        <></>}
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                        >
                            MiDomek
                        </Typography>
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon 
                                    onClick={searchOffers}
                                />
                            </SearchIconWrapper>
                            <StyledInputBase
                                //value={searchWord}
                                placeholder="Szukaj…"
                                inputProps={{ 'aria-label': 'search' }}
                                //onChange={handleSearchType}
                                onKeyDown={handleSearchKeyPress}
                            />
                        </Search>
                    </Toolbar>
                </AppBar>

                <ResultList />
            </Box>
        </>
    )
}

export default MainPage;