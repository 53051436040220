const addOfferToCollection = (collectionName,offer,color) => {
    let items = JSON.parse(window.localStorage.getItem(collectionName));
    if (items === null) items = {};
    offer.storageColor = color;
    items[offer.id]=offer;
    window.localStorage.setItem(collectionName,JSON.stringify(items));
}

const removeOfferFromCollection = (collectionName,offer) => {
    let items = JSON.parse(window.localStorage.getItem(collectionName));
    if (items === null) return;
    delete items[offer.id];
    window.localStorage.setItem(collectionName,JSON.stringify(items));
}

const getOneCollectionArray = (collectionName) => {
    let items = JSON.parse(window.localStorage.getItem(collectionName));
    if (items === null) items = {};    
    return items;
}

const checkOfferInCollection = (collectionArray,offer) => {
    if (offer && offer.id in collectionArray) {
        offer.storageColor = collectionArray[offer.id].storageColor;
        console.log(collectionArray[offer.id].storageColor);
        return collectionArray[offer.id].storageColor;
    }
    else {
        return false;
    };
}

const getCollectionsArrays = () => {
    let result = {};
    result.stickyNotes = getOneCollectionArray("stickyNotes");
    result.favorites = getOneCollectionArray("favorites");
    result.checked = getOneCollectionArray("checked");
    return result;
}

export {addOfferToCollection, removeOfferFromCollection, checkOfferInCollection, getOneCollectionArray, getCollectionsArrays};